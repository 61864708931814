@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  @variants responsive {
    .customDatePicker {
      min-width: 22vw !important;
    }
    .px-10vw {
      padding-left: 10vw !important;
      padding-right: 10vw !important;
    }
    .advantageCardHeight {
      height: 32rem;
    }
    .w-budgetModal {
      width: 28rem;
    }
  }
}

@font-face {
  font-family: "Area";
  src: url("assets/fonts/Area.otf") format("opentype");
  font-weight: normal;
}

@font-face {
  font-family: "Area";
  src: url("assets/fonts/AreaBold.otf") format("opentype");
  font-weight: bold;
}

@font-face {
  font-family: "Area";
  src: url("assets/fonts/AreaBlack.otf") format("opentype");
  font-weight: 900;
}

.App {
  font-family: "Area", sans-serif;
}

/* Custom loader CSS */
.loader {
  border: 3px solid #f3f3f3;
  border-radius: 50%;
  /* border-top: 3px solid #fdc413; */
  width: 40px;
  height: 40px;
  -webkit-animation: spin 1s linear infinite; /* Safari */
  animation: spin 1s linear infinite;
}
.loaderOverlay::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: #00000060;
  /* background: rgba(241, 246, 249, 0.95); */
  backdrop-filter: blur(1px);
}

.MuiAutocomplete-groupLabel {
  margin: 0 !important;
  /* white-space: nowrap; */
  line-height: 1.4 !important;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.swiper-pagination-bullet-active {
  background-color: #000000 !important;
}

.itemDescription ul {
  list-style: initial;
  margin-left: 1.5em;
}

.itemDescription ol {
  list-style: initial;
  margin-left: 1.5em;
}

.itemDescription h1 {
  font-size: 1.5rem;
  /* font-weight: bold; */
}
.itemDescription h2 {
  font-size: 1.25rem;
  /* font-weight: bold; */
}

.itemDescription h3 {
  font-size: 1rem;
  /* font-weight: bold; */
}

/* Removing arrows from number Input */

/* Chrome, Safari, Edge, Opera */
.numberInputWithoutArrows input::-webkit-outer-spin-button,
.numberInputWithoutArrows input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.numberInputWithoutArrows input[type="number"] {
  -moz-appearance: textfield;
}

/* .MuiOutlinedInput-root {
  background-color: white;
} */

.whitebgInput .MuiOutlinedInput-root {
  background-color: white;
}

/* .MuiAutocomplete-paper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: red;
} */

/* .MuiAutocomplete-option {
  text-align: center;
} */
/* 
.MuiAutocomplete-option {
  text-align: center !important;
  margin-left: 1rem;
  margin-right: 1rem;
} */

.pinch::after {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  width: 0;
  height: 0;
  border-left: 13px solid #f3f3f3;
  border-top: 55px solid transparent;
  border-bottom: 55px solid transparent;
  border-radius: 5rem;
}

.PrivatePickersFadeTransitionGroup-root {
  text-transform: capitalize;
}

.PrivatePickersToolbar-dateTitleContainer {
  text-transform: capitalize;
}

.variant-select {
  border-left: 0.2rem solid;
  border-top: 0.2rem  solid;
  border-bottom: 0.2rem  solid;
  border-right: 0.2rem  solid;
  border-color:#FDC413;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
}

.table-container {
  overflow-x: auto;
  width: 100%;
 
  table {
    width: 100%;
    table-layout: fixed;
  }

}

.MuiFormLabel-asterisk{
  visibility: hidden;
}

